import BezierEasing from 'bezier-easing';
import { clamp } from 'lodash-es';

export function reveal(ctx: CanvasRenderingContext2D, delta: number) {
    const easeDelta = easing(delta);

    let scale = Math.min(1.0, Math.max(0.0, easeDelta));

    const scaleOverflow = .2;
    scale *= (1.0 + scaleOverflow);

    if (scale > (1.0 + scaleOverflow / 2)) {
        const x = scale - (1.0 + scaleOverflow / 2);
        scale -= x * 2;
    }

    ctx.scale(scale, scale);
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, easeDelta * 2.0));


    return { motionblur: clamp((1.0 - easeDelta) * 4.0, 0.0, 1.0), motionBlurScale: true }
}

export function fadeIn(ctx: CanvasRenderingContext2D, delta: number) {
    const easeDelta = easing(delta);
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, easeDelta));
}

export function slideRight(ctx: CanvasRenderingContext2D, delta: number) {
    const easeDelta = easing(delta);

    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, easeDelta));
    const deltaX = 1.0 - Math.min(1.0, Math.max(0.0, easeDelta));
    return { xOffset: -deltaX * 50, motionblur: clamp((1.0 - easeDelta) * 4.0, 0.0, 1.0), blurDirection: 'right' };
}

export function slideTop(ctx: CanvasRenderingContext2D, delta: number) {
    const easeDelta = easing(delta);

    const deltaY = 1.0 - Math.min(1.0, Math.max(0.0, easeDelta));
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, easeDelta));
    return { 
        yOffset: deltaY * 20,
        motionblur: clamp((1.0 - easeDelta) * 2.0, 0.0, 1.0),
        blurDirection: 'top'
    };
}


export function floatAround(ctx: CanvasRenderingContext2D, delta: number, timestamp: number, wordDelta: number, wordStartTimestamp: number) {

    const speed = 2.0;
    const rotationSpeed = 2.0;
    const offset = 5;

    const deltaY = Math.sin(timestamp * speed);
    const deltaX = Math.cos(timestamp * speed);

    const rotation = Math.cos(timestamp * rotationSpeed) * 0.01;

    const fadeInDuration = .2 * 1e6;
    const easeDelta = easing((timestamp - wordStartTimestamp) / fadeInDuration);

    if (wordDelta > 1) return;

    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, easeDelta));

    if (easeDelta < 0) return;

    return { yOffset: deltaY * offset, xOffset: deltaX * offset, motionblur: clamp(easeDelta * 1.4, 0, 1), rotation};
}



export const interpolate = (a: number, b: number, progress: number) => {
    return a + (progress * (b-a));
}

export function shrink(ctx: CanvasRenderingContext2D, delta: number) {
    const easeDelta = easing(clamp(delta, 0.0, 1.0));

    const nDelta = 1.0 - easeDelta;
    const scale = interpolate(1.0, 1.5, nDelta);

    ctx.scale(scale, scale);
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, easeDelta * 1.4));
}


function springBounce(t: number) {
    // Ensure input is non-negative
    if (t < 0) return 0;
  
    const settlePoint = 1; // Target value where it settles
    const overshootFactor = 1.2; // Peak value before bounce
    const decayRate = 2; // Controls how quickly the bounce decays
    const frequency = 4; // Number of oscillations
  
    // Calculate the amplitude of the bounce, decaying over time
    const amplitude = Math.exp(-decayRate * t);
  
    // Sine wave for the oscillation
    const oscillation = Math.sin(frequency * Math.PI * t);
  
    const fadeIn = t < .2 ? clamp((t / .2 * 2.0), 0, 1) : 1.0;

    // Combine the overshoot, decay, and oscillation
    return (settlePoint + amplitude * oscillation * (overshootFactor - settlePoint)) * fadeIn;
}

 
export function bounce(ctx: CanvasRenderingContext2D, delta: number) {
    delta /= 2;
    const easeDelta = easing(delta);

    const clampedProgress = Math.min(1.0, Math.max(0.0, easeDelta));

    const scale = springBounce(clampedProgress)

    ctx.scale(scale, scale);
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, easeDelta * 4));


    // return { motionblur: clamp((1.0 - easeDelta) * 5.0, 0.0, 1.0), motionBlurScale: true }
}

const easing = BezierEasing(0.61, 1, 0.88, 1);

const animations = {
    'reveal': reveal,
    'fade-in': fadeIn,
    'slide-right': slideRight,
    'slide-top': slideTop,
    'shrink': shrink,
    'bounce': bounce,
    'float-around': floatAround
}


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default (name: string) => (ctx: CanvasRenderingContext2D, delta: number, timestamp: number, wordDelta: number, wordStartTimestamp: number) => animations[name]?.(ctx, delta, timestamp, wordDelta, wordStartTimestamp)
